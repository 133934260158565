import React from "react"
import { SocialIcon } from "react-social-icons"
import WiggleContainer from "../components/wiggleContainer"

export default class WiggleSocialIcons extends React.Component {
  render() {
    return (
      <div className="contactRightContainer" style={styles.rightContainer}>
        <WiggleContainer style={styles.wiggle} id="wiggle5">
          <SocialIcon style={styles.icon} url="mailto::liam@mcmains.net" />
        </WiggleContainer>

        <WiggleContainer style={styles.wiggle} id="wiggle1">
          <SocialIcon
            style={styles.icon}
            url="https://www.linkedin.com/in/liammcmains/"
          />
        </WiggleContainer>

        <WiggleContainer style={styles.wiggle} id="wiggle2">
          <SocialIcon
            style={styles.icon}
            url="https://medium.com/@liam.john.mcmains"
          />
        </WiggleContainer>

        <WiggleContainer style={styles.wiggle} id="wiggle3">
          <SocialIcon
            style={styles.icon}
            bgColor="rgb(244, 128, 35)"
            url="https://stackoverflow.com/users/4592747/spiritman110"
          />
        </WiggleContainer>
      </div>
    )
  }
}

const styles = {
  icon: {
    height: 125,
    width: 125,
    padding: 20,
  },
  wiggle: {
    maxWidth: 125,
    maxHeight: 125,
    marginLeft: "auto",
  },
  rightContainer: {
    marginTop: 100,
    display: "grid",
    gridTemplateColumns: "40% 40%",
    gridTemplateRows: "40% 40%",
    columnGap: 20,
    rowGap: 60,
    width: "45%",
  },
}
