import React from "react"
import Layout from "../components/layout"
import ShapeParticles from "../components/ShapeParticles"
import FadeIn from "../components/fadeIn"
import WiggleSocialIcons from "./WiggleSocialIcons"

const Contact = () => {
  return (
    <div>
      <ShapeParticles />
      <Layout fixedFooter>
        <FadeIn>
          <div style={styles.parentContainer}>
            <div style={styles.leftContainer} className="contactLeftContainer">
              <LeftContent />
              <HorizontalRule />
            </div>
            <WiggleSocialIcons />
          </div>
        </FadeIn>
      </Layout>
    </div>
  )
}

const HorizontalRule = () => <hr id="specialHR" />

const LeftContent = () => (
  <div>
    <h1 style={styles.letsTalk}>LET'S TALK</h1>
    <h3 style={styles.emailMe}>DON'T BE A STRANGER</h3>

    <hr />

    <p>
      Feel free to reach out and get in touch. I am always open to discussing
      new projects, ideas, and opportunities. Let's turn your vision into
      reality.
    </p>

    <h4 style={styles.noMarginBottom}>Need Help?</h4>
    <p style={styles.contactText}>
      <a href="mailto::liam@mcmains.net">Liam@McMains.net</a>
    </p>

    <h4 style={styles.noMarginBottom}>Want to Chat?</h4>
    <p style={styles.contactText}>
      <a href="tel:+5128787028">+1 (512)-878-7028</a>
    </p>
  </div>
)

export const styles = {
  letsTalk: {
    fontSize: "3rem",
    marginTop: 10,
    color: "#000",
    marginBottom: 5,
  },
  emailMe: {
    color: "#555",
    marginTop: 0,
    marginLeft: 15,
  },
  socialIcons: {
    width: "100%",
    maxWidth: 300,
    justifyContent: "space-between",
    marginTop: 75,
    paddingBottom: 60,
  },
  leftContainer: {
    width: "45%",
  },
  noMarginBottom: {
    marginBottom: 5,
  },
  contactText: {
    marginLeft: 10,
  },
  parentContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}

export default Contact
